.hero {
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;

    @include respond-to(small) {
        flex-direction: row;
        justify-content: space-between;
    }
    &-text {
        font-family: 'Barlow', sans-serif;
        text-wrap: balance;
        padding-block: 10%;
        text-align: center;
        margin-top: 20%;
        color: var(--white);
        position: absolute;
        bottom: 40px;
        width: 100%;
        
        h1 {
            font-size: 2.1rem;
            font-weight: 600;
            line-height: 1em;
            text-align: center;
            @include respond-to(medium) {
                // Styles for screens with width >= 1600px (small breakpoint) and up
                font-size: 6rem;
                margin-bottom: 20px;
            }
        }
        span {
            font-size: 1.3rem;
            font-weight: 300;
            line-height: 1em;
        }
        p {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.55em;
            background: linear-gradient(45deg, var(--white), var(--white));
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            margin-inline: 5%;
            @include respond-to(medium) {
                // Styles for screens with width >= 1600px (small breakpoint) and up
                font-size: 2.2rem;
            }
        }
        .hero-link {
            color: var(--orange);
            text-decoration: none;
        }
        a {
            text-decoration: none;
            &:hover {
            &:not(:nth-child(3)) { 
                color: var(--white);
                }
            }
        }
    }
    &-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include respond-to(small) {
            // Styles for screens with width >= 1600px (small breakpoint) and up
            width: 50%;
        }
    }
    .btn-star:hover:before {
        border-color: var(--orange);
    }
}