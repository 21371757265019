#footer {
    background-color: var(--background);
    font-family: 'Barlow', sans-serif;
    width: 100%;
    height: 100%;
    max-height: 30%;
    padding-top: 60px;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    li {
        margin-bottom: 20px;
    }
    .link-col {
        margin-top: 2.5%;
        margin-inline: 1rem;
        @include respond-to(medium) {
            margin-inline: 10rem;
          } 
    }
    .link {
        color: var(--white);
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 200;
        line-height: 1.15em;
        text-align: center;
        @include respond-to(medium) {
            font-size: 1.6rem;
          } 
    }
    .back-to-top {
        background-color: var(--white);
        padding: 10px;
        border-radius: 100%;
        float: right;
        margin-right: 5%;
        position: relative;
        @include respond-to(medium) {
            top: -25%;
          } 
        svg {
            margin: auto;
        }
    }
}