#products {
  h2 {
    color: #fefeff;
    text-align: left;
    font-size: 2.5rem;
    line-height: 1.45em;
    text-transform: uppercase;
    padding-inline: 30px;
    @include respond-to(medium) {
      margin-bottom: 5rem;
      text-align: center;
      br {
        display: none;
      }
    } 
  .highlight {
    color: var(--orange);
  } 
  }
}

.featured-product {
    display: flex;
    justify-content: center;
    border-radius: var(--border-radius);
    min-height: 35vh;
    margin: auto;
    margin-bottom: 5%;
    position: relative;
    top: 5vh;
    padding: 30px;
    flex-direction: column;
    @include respond-to(medium) {
        margin-inline: auto;
        padding: 0;
        flex-direction: row;
    }  
    .featured-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;    
        align-items: center;    
        max-width: 40rem;  
        margin-bottom: 5rem;
        margin-inline: auto;
        @include respond-to(medium) {
          padding-inline: 2rem;
          margin-inline: unset;
        }
    }
    .featured-image {
        max-width: 100px;
        margin-block: 1%;
        margin-inline: auto;
        border-radius: var(--border-radius);
        @include respond-to(medium) {
            margin-inline: unset;
            margin-right: 25px;
            max-width: 300px;
          }  
    }
    .featured-text {
        color: #fefeff;
        margin-top: 2%;
        margin-inline: auto;
        @include respond-to(medium) {
            margin-inline: unset;
            margin-right: 10px;
          }
        h3 {
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 1.15em;
            padding: 10px;
            text-align: left;
            color: var(--orange);
            @include respond-to(medium) {
                font-size: 1.8rem;
                padding: 0;
                text-align: center;
              }
          }
        p {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.55em;
            margin-bottom: 10px;
            padding: 10px;
            @include respond-to(medium) {
                padding: 0;
                text-align: left;
                margin-bottom: 40px;
              }
        }
        .btn {
            display: flex;
            text-align: center;
            justify-content: center;
            max-width: 15rem;
            min-width: 115px;
            margin: auto;

            @include respond-to(medium) {
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%) !important;
              bottom: -25px;
              margin: auto;
            }
        }
    }
}