// Buttons

.btn {
    background-color: var(--blue);
    color: var(--white);
    margin: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 12px;
    position: relative;
    text-align: center;
    transition: all .15s linear;
    border-radius: 3px;
    font-size: 14px;
    padding: 12px 24px;
    &:hover {
      background-color: var(--orange);
    }
    @include respond-to(medium) {
      padding: 9px 15px 9px 15px;
    }  

    &.outline {
      background-color: transparent;
      border: 2px solid var(--white);
      &:hover {
        background-color: var(--orange);
        border: 2px solid var(--orange);
      }
    }
}

// Glitch Button
@keyframes StarBackground {
    10% {
      background-color:rgba(255,255,255,0.1);
      -webkit-filter: blur(1px);
      -moz-filter: blur(1px);
      -o-filter: blur(1px);
      -ms-filter: blur(1px);
      filter: blur(1px);
    }
    20% {
      background-color:rgba(255,255,255,0);
    }
    40% {
      background-color:rgba(255,255,255,0.05);
    }
    50% {
      background-color:rgba(255,255,255,0);
    }
    60% {
      background-color:rgba(255,255,255,0.3);
    }
    70% {
      background-color:rgba(255,255,255,0);
      -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px);
    }
    80% {
      background-color:rgba(255,255,255,0.2);
    }
    90% {
      background-color:rgba(255,255,255,0.1);
    }
    100% {
      background-color:rgba(255,255,255,0.09);
    }
  }
  @keyframes StarBorderColor {
    10% {
      border-color:rgba(255,255,255,0.1);
    }
    20% {
      border-color:rgba(255,255,255,0.05);
    }
    40% {
      border-color:rgba(255,255,255,0);
    }
    50% {
      border-color:rgba(255,255,255,0.3);
    }
    60% {
      border-color:rgba(255,255,255,0);
    }
    70% {
      border-color:rgba(255,255,255,0.2);
    }
    80% {
      border-color:rgba(255,255,255,0.7);
    }
    90% {
      border-color:rgba(255,255,255,0);
    }
    100% {
      border-color:rgba(255,255,255,0.4);
    }
  }
  .btn-star {
    text-decoration: none;
    text-align: center;
    position:relative;
    background-color:rgba(255,255,255,0.03);
    padding:10px 30px;
    display:block;
    max-width:140px;
    margin: 0 auto;
    color:rgba(255,255,255,0.6);
    font-weight:100;
    transition:color 0.5s ease , background-color 0.1s ease;
    .top_left , .top_right , .bottom_left , .bottom_right {
      width:10px;
      height:10px;
      position:absolute;
    }
    .bottom_right , .bottom_left {
      bottom:0;
      border-bottom:1px solid var(--white);
    }
    .top_left , .top_right {
      top:0;
      border-top:1px solid var(--white);
    }
    .top_left , .bottom_left {
      left:0;
      border-left:1px solid var(--white);
    }
    .top_right , .bottom_right {
      right:0;
      border-right:1px solid var(--white);
    }
    &:before {
      content:'';
      position:absolute;
      width:100%;
      height:100%;
      border:1px solid transparent;
      top:0;
      left:0;
      transition:border-color 0.2s ease;
    }
    &:hover {
      -webkit-animation: StarBackground 0.5s; /* Safari 4.0 - 8.0 */
      animation: StarBackground 0.5s;
      background-color:rgba(255,255,255,0.09);
      color:rgba(255,255,255,1);
      .top_left , .top_right , .bottom_left , .bottom_right {
        border:none;
      }
      &:before {
        border-color:rgba(255,255,255,0.4);
        -webkit-animation: StarBorderColor 1s; /* Safari 4.0 - 8.0 */
        animation: StarBorderColor 1s;
      }
    }
  }