.post-page {

    @include respond-to(small) {
        #footer {
            .back-to-top {
                position: fixed !important;
                bottom: 2.2rem;
                right: 0;
                top: unset !important;
            }
        }
    }
    
    .navbar {
        background-color: var(--white);
        @include respond-to(small) {
            background-color: var(--background);
            a {
                color: var(--white) !important;
            }
        }
    }

    .post {
        background-color: var(--background);

        .blog-post {
            padding-inline: 10%;
            background-color: var(--white);
            padding-top: 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 800px;
            white-space: pre-line;
            @include respond-to(small) {
                border-radius: var(--border-radius);
                margin-inline: auto;
                position: relative;
                top: 12rem;
            }

            img {
                align-self: center;
                border-radius: var(--border-radius);
            }
            h1 {
                font-size: 2.1rem;
                font-weight: 600;
                line-height: 1em;
                color: var(--off-black);
                text-align: center;
                @include respond-to(small) {
                    font-size: 4rem;
                    margin-bottom: 20px;
                }
            }
            .date {
                text-align: center;
                color: var(--off-black);
                span {
                    color: var(--blue);
                    padding-inline: 5px;
                }
            }
            h2 {
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 1em;
                color: var(--off-black);
                text-align: center;
                @include respond-to(small) {
                    font-size: 2.4rem;
                    margin-bottom: 20px;
                }
            }
            h3, .credit {
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 1em;
                color: var(--off-black);
                @include respond-to(small) {
                    font-size: 2rem;
                    margin-bottom: 10px;
                }
            }
            pre {
                overflow-x: auto; 
                white-space: pre-wrap; 
                word-wrap: break-word; 
                padding: 10px; 
                font-size: 1.3rem;
                background-color: rgba(211, 211, 211, 0.387);
                @include respond-to(small) {
                    font-size: 1.8rem;
                }
            }
            pre code {
                display: block; 
                padding: 0; 
            }
            p {
                font-size: 1.3rem;
                font-weight: 400;
                line-height: 1.55em;
                color: var(--off-black);
                @include respond-to(small) {
                    font-size: 1.8rem;
                }
            }
            .credit {
                text-align: center;
                padding: 50px;
            }
        }
    }

    #newsletter-signup {
        margin-top: 4rem;
        @include respond-to(small) {
            margin-top: 20rem;
        }
    }
}