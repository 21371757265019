#newsletter-signup, #contact-form {
    font-family: 'Barlow', sans-serif;
    background-color: var(--white);
    border-radius: var(--border-radius);
    border: 8px solid var(--orange);
    margin-inline: 5%;
    padding: 10px 20px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    @include respond-to(large) {
        max-width: 60rem;
        margin: auto;
        padding: 10rem 50px;
      } 
      svg {
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        top: 50%;
        @include respond-to(large) {
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
}

#featured-form {
  height: 100%;
}
