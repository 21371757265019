#featured-blogs { 
    padding-inline: 30px;
    @include respond-to(medium) {
        padding-inline: unset;
        border: 3px solid var(--orange);
        padding-bottom: 100px;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    }
    font-family: 'Barlow', sans-serif;
    h2 {
        color: var(--orange);
        text-align: left;
        font-size: 2.5rem;
        line-height: 1.45em;
        text-transform: uppercase;
        @include respond-to(medium) {
            font-size: 3.5rem;
            text-align: center;
        }
    }
    .heading {
        text-align: center;
        color: white;
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 2em;
        @include respond-to(medium) {
            font-size: 1.6rem;
        }
        span {
            text-transform: uppercase;
            color: var(--orange);
        }
    }
    .feature-wrap {
        display: flex;
        flex-direction: column;
        @include respond-to(medium) {
            justify-content: center; 
            flex-direction: row;
          } 
        @include respond-to(large) {
            justify-content: center; 
          } 
    }
    .featured-blog {
        color: var(--white);
        margin-inline: auto;
        margin-bottom: 6rem;
        position: relative;
        @include respond-to(medium) {
            max-width: calc(33.33vw / 2);
            margin-inline: 5%;
            margin-top: 50px;
            margin-bottom: 0;
          } 
        .heading {
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 1.15em;
            text-wrap: balance;
            text-align: left;
            @include respond-to(medium) {
                text-align: center;
            }
        }
        .excerpt {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.55em;
            margin-bottom: 40px;
            max-width: 50rem;
        }
        .btn {
            display: flex;
            text-align: center;
            justify-content: center;
            max-width: 15rem;
            min-width: 115px;
            margin: auto;

            @include respond-to(medium) {
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%) !important;
              bottom: -25px;
              margin: auto;
            }
        }
    }
}