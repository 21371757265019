.navbar {
  padding-block: 0.75rem;
  z-index: 1000;
  position: absolute;
  padding: 20px;

  @include respond-to(medium) {
    padding: 0;
    left: 0;
    right: 0;
  }

  .home-link.active {
    background: var(--orange);
  }

  .home-link {
    transition: 0.3s ease-in-out;
    display: none;
    z-index: 998;
    position: relative;
    top: -20px;
    left: -40px;
    margin: 20px 30px;
    border: 3px solid var(--orange);
    border-radius: 100%;

    @include respond-to(medium) {
      display: block;
      position: fixed;
      top: 0px;
      left: 0px;
    } 
  }

  .menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 999;
    position: fixed;
    right: 20px;
    top: 20px;
    div {
      width: 30px;
      height: 4px;
      background-color: var(--off-black);
      margin: 2px 0;
      transition-duration: 0.4s;
    }

    &.open div:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
      background-color: var(--orange);
    }

    &.open div:nth-child(2) {
      opacity: 0;
    }

    &.open div:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
      background-color: var(--orange);
    }
  }

  ul {
    padding-inline-start: 20%;
    padding-inline-end: 20%;
    font-family: 'Barlow', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    font-size: 1.75rem;
    font-weight: 600;
    text-align: right;
    @include respond-to(medium) {
      font-size: 1.75rem;
      flex-direction: row;
    }  

    li {
      padding-block: 2rem;
    }

    .link {
      text-decoration: none;
      color: var(--white);
      @include respond-to(medium) {
        color: var(--off-black);
      } 

      &:hover {
        color: var(--white);
      }
    }

    .link.mobile {
      display: block;
      @include respond-to(medium) {
        display: none;
      } 
    }

    &.show-menu {
      position: fixed;
      top: -20px;
      left: -20px;
      display: flex;
      flex-direction: column;
      background-color: var(--background);
      z-index: 998;
      height: 100vh;
      width: 70vw;
    }
  }

  @media only screen and (max-width: 768px) {
    .menu-icon {
      display: flex;
    }

    ul {
      display: none;

      &.show-menu {
        display: block;
        padding-block: 20%;
      }
    }
  }
}
