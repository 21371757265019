.affiliates-content {
    background-color: var(--background);
    .hero {
        background-image: url('../../assets/affiliates-hero-mobile.png');
        @include respond-to(small) {
            background-image: url('../../assets/affiliates-hero.jpg');
        } 
    }
    .affiliates-grid {
        padding-inline: 50px;
        display: grid;
        grid-template-columns: repeat(4, 1fr); 
        grid-auto-rows: auto; 
        grid-gap: 10px; 
        @include respond-to(large) {
            padding-inline: 250px;
        }
    }
    .affiliate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 200px;
        height: auto;
        img {
            width: 100%;
            border-radius: 100%;
            margin-bottom: 15px;
        }
        span {
            color: var(--white);
            text-align: center;
            font-size: 1.4rem;
        }
    }
    #newsletter-signup {
        margin-top: 20rem;
    }
} 