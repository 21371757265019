body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.max-container {
  height: 100vh;
  margin: auto;
  background-color: var(--white); 
}

.container {
  margin-inline: 5%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(180deg, rgba(0,0,0,0.35) 75%, rgba(12,28,41,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.35) 75%, rgba(12,28,41,1) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0.35) 75%, rgba(12,28,41,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#0c1c29",GradientType=1);
}

.overlay.primary {
  height: 100vh;
}

.overlay.secondary {
  height: 100%;
}

.full-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity (last value) as needed */
  z-index: 1000; /* Adjust the z-index as needed to ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

a {
  text-decoration: none;
  color: unset;
}