.faq-item {
    background-color: var(--orange);
    border-radius: 5px;
    margin: 20px 15px;
    padding: 10px 15px;
    transition: 0.3s ease-in-out;
    &:hover {
        background-color: #fa9768; 
    }
    &.expanded {
        background-color: var(--creamy-jeans);
        box-shadow: 50px 50px 100px rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.2); 
        .faq-question, .faq-answer {
            color: var(--off-black);
        }
    }
    .faq-question {
        font-size: 2.1rem;
        font-weight: 600;
        line-height: 1em;
        @include respond-to(small) {
            font-size: 4rem;
            margin-bottom: 20px;
        }
    }
    .faq-answer {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.55em;
        @include respond-to(small) {
            font-size: 1.8rem;
        }
    }
}