.home-content {
    background-color: var(--background);
    .hero {
        background-image: url('../../assets/200x.png');
        @include respond-to(small) {
            background-image: url('../../assets/400x.png');
        } 
    }
    .form-copy {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.55em;
        background: linear-gradient(45deg, var(--white), var(--white));
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        max-width: 60rem;
        margin-inline: 5%;
        margin-bottom: 100px;
        @include respond-to(medium) {
            font-size: 1.8rem;
            margin-inline: auto;
        }
    }
    #newsletter-signup, #featured-blogs, #products {
        margin-bottom: 15rem;
        @include respond-to(small) {
            margin-bottom: 30rem;
        } 
    }
}