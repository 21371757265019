// _breakpoints.scss

$breakpoints: (
  small: 576px,
  medium: 768px,
  large: 992px,
  xlarge: 1200px,
  xxlarge: 1600px,
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @error "Invalid breakpoint: #{$breakpoint}. Make sure it exists in the breakpoints map.";
  }
}
