.contact {
    background-position: bottom;
    background-size: cover;
    position: relative;
    padding-bottom: 100px;
    @include respond-to( xlarge ) {
        height: 100vh;
    }
    @include respond-to( xxlarge ) {
        background-position: center;
    }
    .content {
        position: relative; /* Ensure your content is on top of the overlay */
        z-index: 1;
    }
    h2 {
        text-transform: uppercase;
        font-size: 2rem;
        color: var(--white);
        max-width: 75vw;
        padding: 5% 10%;
        text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-flow: column;
        @include respond-to(small) {
            font-size: 4rem;
        }
        @include respond-to( xlarge ) {
            font-size: 7rem;
        }
        @include respond-to( xxlarge ) {
            font-size: 9rem;
            max-width: 50vw;
        }
    }
    .btn-star {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .overlay {
        background: rgb(12,28,41);
        background: -moz-linear-gradient(180deg, rgba(12,28,41,1) 0%, rgba(0,0,0,0.75) 25%, rgba(0,0,0,0.75) 75%, rgba(12,28,41,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(12,28,41,1) 0%, rgba(0,0,0,0.75) 25%, rgba(0,0,0,0.75) 75%, rgba(12,28,41,1) 100%);
        background: linear-gradient(180deg, rgba(12,28,41,1) 0%, rgba(0,0,0,0.75) 25%, rgba(0,0,0,0.75) 75%, rgba(12,28,41,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0c1c29",endColorstr="#0c1c29",GradientType=1);
      }
}