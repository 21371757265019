.fourOhfour-content {
    background-color: var(--background);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;

    .hero {
        background-image: url('../../assets/404-mobile.png');
        @include respond-to(small) {
            background-image: url('../../assets/404.jpg');
        } 
    }
}