.blog-content {
    background-color: var(--background);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
    }

    #newsletter-signup {
        margin-block: 25rem;
    }

    .hero {
        background-image: url('../../assets/blog-background-mobile.png');
        @include respond-to(small) {
            background-image: url('../../assets/blog-background.png');
        } 
    }

    .heading {
        text-align: center;
        padding-top: 8rem;
        padding-bottom: 8rem;
        .category-options {
            .btn {
                margin-inline: 0.3rem;
            }
        }
        .no-posts {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .category-options {
        position: sticky;
        top: -1px;
        padding-block: 20px;
        background-color: var(--background);
        z-index: 20;
        display: flex;
        justify-content: center;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            max-width: 10rem;
            padding-block: 0;
            top: 20px;
        }
        .btn {
            @media only screen and (max-width: 768px) {
                margin-inline: 0 !important; 
           }
        }
    }
    .category {
        text-align: center;
        font-size: 2.1rem;
        font-weight: 600;
        line-height: 1em;
        color: var(--white);

        @include respond-to(small) {
            font-size: 4rem;
            margin-bottom: 90px;
        }
    }
    .blog-posts {
        margin: auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 10px;
        padding-bottom: 15rem;
        padding-inline: 5%;
        @include respond-to(small) {
            grid-template-columns: 1fr;
        }
        
        @include respond-to(medium) {
            grid-template-columns: repeat(2, 1fr);
        }
        
        @include respond-to(xlarge) {
            grid-template-columns: repeat(3, 1fr);
        }
        
        @include respond-to(xxlarge) {
            grid-template-columns: repeat(4, 1fr);
        }
        h3 {
            font-size: 2.1rem;
            font-weight: 600;
            line-height: 1em;
            color: var(--white);
            @include respond-to(small) {
                font-size: 2.6rem;
                margin-bottom: 20px;
            }
        }
        p {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.55em;
            background: linear-gradient(45deg, var(--white), var(--white));
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            @include respond-to(small) {
                font-size: 1.8rem;
            }
        }
    }
    .blog-post {
        max-width: 30rem;
        margin-inline: 3rem;
        @media only screen and (max-width: 768px) {
            margin-inline: auto;
        }
        img {
            display: flex;
            margin: auto;
        }
    }
}