.max-container.contact {
    .overlay {
        position: relative; /* Cover the entire viewport */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.05); /* Dark semi-transparent color */
        z-index: 999; /* Make sure it's on top of other elements */
    }
    .navbar ul .link {
        color: var(--white);
    }
}

.contact-content {
    background-color: var(--background);
    background-image: url('../../assets/contact-mobile.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    @include respond-to(small) {
        background-image: url('../../assets/contact.jpg');
    } 

    #contact-form {
        margin: 1rem 2rem;
        position: relative;
          
        @include respond-to(small) {
            min-width: 50rem;
            margin: 0 auto;
            margin-bottom: 20rem;
        }
    }

    .text {
        color: var(--white);
        max-width: 500px;
        margin-inline: 1rem;
        margin-block: 5rem;
        @include respond-to(small) {
            margin-inline: auto;
            margin-block: 20rem;
        }
        h2 {
            font-size: 2.1rem;
            font-weight: 600;
            line-height: 1em;
            @include respond-to(small) {
                font-size: 4rem;
                margin-bottom: 20px;
            }
        }
        span {
            color: var(--white);
        }
        p {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.55em;
            background: linear-gradient(45deg, var(--white), var(--white));
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            @include respond-to(small) {
                font-size: 1.8rem;
            }
        }
    }
}