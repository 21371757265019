.faqs-content {
    background-color: var(--background);
    .hero {
        background-image: url('../../assets/faq-hero-mobile.png');
        @include respond-to(small) {
            background-image: url('../../assets/faq-hero.jpg');
        } 
    }
    .faqs-container {
        color: var(--white);
        max-width: 800px;
        margin-inline: 1rem;
        margin-block: 10rem;
        @include respond-to(small) {
            margin-inline: auto;
        }
        h2 {
            font-size: 2.1rem;
            font-weight: 600;
            line-height: 1em;
            @include respond-to(small) {
                font-size: 4rem;
                margin-bottom: 20px;
            }
        }
        span {
            color: var(--white);
        }
        p {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 1.55em;
            background: linear-gradient(45deg, var(--white), var(--white));
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            @include respond-to(small) {
                font-size: 1.8rem;
            }
        }
    }
}